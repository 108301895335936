<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Statistik pasien
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="12">
          <label for="jenis_kelamin">Jenis Kelamin</label>
          <Multiselect
            id="jenis_kelamin"
            v-model="dataFilter.jenis_kelamin"
            :options="listJenisKelamin"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Jenis Kelamin --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="4">
          <ByUmur v-if="!busy" :by_umur="by_umur" />
        </b-col>
        <b-col cols="4">
          <ByAsuransi v-if="!busy" :by_asuransi="by_asuransi" />
        </b-col>
        <b-col cols="4">
          <ByProgram v-if="!busy" :by_program="by_asuransi" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ByUmur from './by_umur.vue'
import ByAsuransi from './by_asuransi.vue'
import ByProgram from './by_program.vue'
export default {
  components:{
    ByUmur,
    ByAsuransi,
    ByProgram,
  },
  data() {
    return {
      busy: false,
      dataFilter: {
        jenis_kelamin: null,
      },
      listJenisKelamin:[
        { value: null , text: "Semua"},
        { value: "L" , text: "Laki - Laki"},
        { value: "P" , text: "Perempuan"},
      ],
      by_umur: [],
      by_asuransi: [],
      by_program: [],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.reset()
  },
  methods: {
    async cari(){
      const vm = this
      vm.busy = false
      try {
        const res = await vm.$axios.post('/anamnesa/laporan_statistik_pasien', {jenis_kelamin: vm.dataFilter.jenis_kelamin.value})
        // console.log('laporan_statistik_pasien', res)
        if(res.data.status == 200){
          // console.log('laporan_statistik_pasien', res.data.data)
          vm.by_umur = res.data.data[0].grafik_pasien_menurut_umur
          vm.by_asuransi = res.data.data[1].grafik_pasien_menurut_asuransi
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataFilter = {
        jenis_kelamin: vm.listJenisKelamin[0],
      }
      vm.cari()
    }
  },
}
</script>